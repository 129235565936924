const Aos = {
  init: function () {
    window.AOS = require('aos/dist/aos');
    // TODO Implement animations with GSAP Scroll Trigger!!
    if (!!window.AOS) {
      AOS.init({
        startEvent: 'DOMContentLoaded',
        once: true,
        duration: 600,
        offset: 0,
        disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
      });
    }
  },
  refresh: function () {
    if (!!window.AOS) {
      setTimeout(() => {
        window.AOS.refresh();
      }, 100);
    }
  },
};

export { Aos };
export default Aos;